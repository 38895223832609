@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800;900&display=swap');

body {
  font-family: 'Inter', Arial, sans-serif;
  @apply text-base;
}

*:focus-visible {
  @apply outline;
  @apply outline-[3px];
  @apply outline-blue-900;
}

.slide-up {
  animation: slideUp 0.5s ease forwards;
  will-change: transform;
}

@-webkit-keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.no-scroll {
  overflow: hidden;
  position: relative; /* iOS Safari */
}

.bottom-shadow {
  box-shadow: 0 5px 10px -10px black;
}

.svg-shadow {
  filter: drop-shadow(0px 2px 0px rgb(0 0 0 / 0.1));
}
